import { useEffect } from "react";

export default function Home() {
  useEffect(function () {
    document.title = "Home Page";
  }, []);
  return (
    <>
      <section className="section">
        <h1 className="section-title">Selamat Datang di My Website</h1>
        <p className="section-description">
          Haloo!! Selamat datang di website pertama saya, Ini merupakan projekan
          pertam saya menggunakan react js
        </p>
      </section>
    </>
  );
}
