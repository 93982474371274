import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Blog() {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(function () {
    document.title = "Blog Page";
    async function getArtikel(params) {
      const request = await fetch(
        "https://portal.datadebasa.com/api/v3.0/artikel?max=1000"
      );
      const response = await request.json();
      console.log(response.payload.data);
      setArticles(response.payload.data);
      setLoading(false);
    }
    getArtikel();
  }, []);

  return (
    <>
      <section className="section">
        <h1 className="section-title">Artikel</h1>
        <p className="section-description">
          Berikut ini merupakan beberapa karya yang saya tulis
        </p>
        {loading && <i>Loading article ....</i>}
        {!loading && (
          <div className="article">
            {articles.map(function (article) {
              return (
                <article key={article.post_title_seo} className="article">
                  <h2 className="article-title">
                    <Link to={`/artikel/${article.post_title_seo}`}>
                      {article.post_title}
                    </Link>
                  </h2>
                  <time className="article-time">
                    {new Date(article.post_time).toLocaleDateString()}
                  </time>
                </article>
              );
            })}
          </div>
        )}
      </section>
    </>
  );
}
