import { useEffect } from "react";

export default function Profile() {
  useEffect(function () {
    document.title = "Profile Page";
  });
  return (
    <section className="section">
      <h1 className="section-title">Profil</h1>
      <p className="section-description">
        Hallo nama saya sodikin saya merupakan mahasiswa yang baru lulus kuliah
        jenjang S1 Program studi S1 Informatika
      </p>
    </section>
  );
}
