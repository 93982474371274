import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
// /v4/articles/{id}/
export default function BlogDetail() {
  const params = useParams();
  const [article, setArticle] = useState({});
  const [loading, setLoading] = useState(true);
  const [notFoud, setNotfound] = useState(false);
  const [content, setContent] = useState();

  useEffect(
    function () {
      async function getArtikel() {
        const request = await fetch(
          `https://portal.datadebasa.com/api/v3.0/artikel/${params.id}`
        );
        if (!request.ok) {
          setLoading(false);
          return setNotfound(true);
        }
        const response = await request.json();
        console.log(response.payload.data);
        setArticle(response.payload.data);
        setLoading(false);
        document.title = response.payload.data.post_title;
        const content = parse(response.payload.data.post_content);
        setContent(content);
      }
      getArtikel();
    },
    [params]
  );
  if (notFoud) {
    return <h1>Artikel tidak ditemukan :C</h1>;
  }
  return (
    <>
      <section className="section">
        {loading ? (
          <i>Loading artikel ...</i>
        ) : (
          <article className="article">
            <h1 className="article-title">{article.post_title}</h1>
            <time className="article-time">
              {new Date(article.post_time).toLocaleDateString()}
            </time>
            <img
              src={
                article.post_thumbnail
                  ? `https://portal.datadebasa.com/upload/${article.post_thumbnail}`
                  : "https://portal.datadebasa.com//upload/courses-2.jpg"
              }
              className="article-image"
            />
            <p className="article-summary">{content}</p>
            <p className="article-source">Autrhor : {article.username}</p>
          </article>
        )}
      </section>
    </>
  );
}
