export default function Contact() {
  document.title = "Contact page";
  return (
    <>
      <section className="section">
        <h1 className="section-title">Contact</h1>
        <p className="section-description">
          Kamu dapat menghubungi saya di bawah ini:{" "}
        </p>
        <ul>
          <li>Wa : 082293973119</li>
          <li>e-mail : datadebasa@gmail.com</li>
        </ul>
        <p>Atau dapat melihat konten lainya saya di sosial media berikut</p>
        <ul>
          <li>
            <a href="https://youtube.com/@datadebasa">Youtube</a>
          </li>
          <li>
            <a href="https://facebook.com/sodikinTeknokrat">Facebook</a>
          </li>
        </ul>
      </section>
    </>
  );
}
